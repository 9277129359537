@import '../constants/colors';

body {
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: white;

  .migration-message {
    width: 60%;
    margin: 0 auto;
    text-align: center;

    h1 {
      font-size: 2.5rem;
    }

    p {
      font-size: 1.2rem;
    }

    a {
      text-decoration: underline;
    }

    .logo {
      width: 174px;
      height: 51px;
      margin: 10px auto 0;
      background-image: url('../../images/logo174x75.png');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }

    .logos {
      display: flex;
      align-items: center;
    }

    .logo-csnri {
      width: 200px;
      height: 70px;
      margin: 10px auto 0;
      background-image: url('../../images/CSNRI-full-logo.png');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }

    .logo-loctite {
      width: 174px;
      height: 51px;
      margin: 10px auto 0;
      background-image: url('../../images/Loctite-logo.png');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
}
